<template>

    <div class="card1">
        <h3>Business Card1</h3>
        <img src="../assets/card-image1.png" alt="Business Card 1">
        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae voluptate fugiat corrupti, repudiandae dolorem fugit, ratione ipsum totam modi numquam qui accusamus nostrum, aspernatur facilis sed repellendus! Vitae, alias modi.</p>
    </div>

</template>

<script>
export default {
  name: 'BusinessCard'
}
</script>
<style>
.card1 {
  border: 1px solid black;
  width: 18vw;
}
</style>