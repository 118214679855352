<template>
  <div class="test1">
    <div class="hello">
      <BusinessCard></BusinessCard>
    </div>
    <div class="hello">
      <BusinessCard></BusinessCard>
    </div>
    <div class="hello">
      <BusinessCard></BusinessCard>
    </div>
  </div>
  <div class="test1">
    <div class="hello">
      <BusinessCard></BusinessCard>
    </div>
    <div class="hello">
      <BusinessCard></BusinessCard>
    </div>
    <div class="hello">
      <BusinessCard></BusinessCard>
    </div>
  </div>
</template>

<script>
import BusinessCard from './BusinessCard.vue'

export default {
  name: 'HelloWorld',
  components: {
    BusinessCard // Registering the BusinessCard component
  },
  props: {
    msg: String
  }
}
</script>
<style>
.test1 {
  display: flex;
  justify-content: center;
}
.hello {
    margin-right: 10px;
}
</style>
